// In src/Page.js

import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Layout from '../components/Global/Layout'
import BodyWrapper from '../components/Styled/BodyWrapper'
import Seo from '../components/Global/Seo'

// Declare your component
const Warranty = () => {
  return (
    <Layout>
      <Seo />
      <BodyWrapper>
        <Container>
          <Row>
            <Col md={12}>
              <div>
                <h3>
                  JOTO ROBOTIC DRAWING BOARDS, STANDS &amp; POWER ADAPTORS
                </h3>
                <p>
                  This limited warranty applies to the following Joto items
                  only: any Joto robotic drawing board, any Joto power adaptor
                  shipped with a Joto robotic drawing board, or any Joto robotic
                  drawing board (each a “Product”). This limited warranty does
                  not apply to any other Joto items, including, but not limited
                  to, Joto ink refills and accessories.
                </p>
                <p>
                  This Limited Warranty shall only apply to purchases made by
                  Customers in North America, Europe and the Asia-Pacific
                  region, unless otherwise stated by us.
                </p>
                <p>
                  Those Ltd. &nbsp;(“Joto”) warrants to the original consumer
                  purchaser that any Joto Product shall be free from material
                  defects in material and workmanship for a period of one (1)
                  year from the original date of purchase for (the "Warranty
                  Period").
                </p>
                <h3>SOLE REMEDY OF REPAIR OR REPLACEMENT</h3>
                <p>
                  If the product is determined to be materially defective during
                  the Warranty Period, your sole remedy and Joto’s sole and
                  exclusive liability under this warranty shall be limited to
                  the repair or replacement of the product with a new or
                  refurbished product at Joto’s option. For purpose of this
                  Limited Product Warranty and Liability, "refurbished" means a
                  product that has been returned to its original specifications.
                  Claims other than the right to correction of faults in the
                  Product named in this Limited Warranty are not covered by our
                  Limited Warranty.
                </p>
                <h3>NON-EXTENSION OF WARRANTY</h3>
                <p>
                  In the event that Joto repairs or replaces your product under
                  warranty, the repair or replacement will remain covered by
                  this warranty for the remainder of the Warranty Period or,
                  where the Warranty Period has expired, for thirty (30) days
                  from the date that the product is returned to you. The
                  warranty term for the Product is not extended or recommenced
                  as a consequence of work performed by us under this Limited
                  Warranty.
                </p>
                <h3>&nbsp;</h3>
                <h3>CLAIM CONDITIONS</h3>
                <p>
                  All warranty claims are subject to Joto’s: (i) receipt of
                  notice of a defect within the warranty period; (ii)
                  verification of the existence of the defect in the product;
                  and (iii) receipt of valid proof of the purchase date.
                </p>
                <h3>INELIGIBLE PRODUCTS</h3>
                <p>
                  This warranty is void and will not apply if the product is:
                </p>
                <ul>
                  <li>
                    Used with other products or items (including ink and nibs)
                    that are not compatible with the product (i.e. nibs (or
                    pens) other than that supplied or approved by Joto).
                  </li>
                  <li>
                    Modified, altered, opened, or otherwise tampered with;
                  </li>
                  <li>
                    Damaged by improper service and/or attempted service by a
                    party other than Joto;
                  </li>
                  <li>
                    Damaged by accident, unreasonable use, neglect, abuse,
                    misuse, careless handling, overburdening, improper
                    maintenance, or other reasons unrelated to defective
                    materials or workmanship;
                  </li>
                  <li>Immersed in water or otherwise exposed to moisture;</li>
                  <li>
                    Damaged such that the serial number is altered, defaced or
                    removed;
                  </li>
                  <li>
                    Used in a manner for which it is not designed, including,
                    but not limited to, handling, storage, installation,
                    testing, or use not in accordance with the operating
                    instructions;
                  </li>
                  <li>
                    Used with products not sold or licensed by Joto, including,
                    but not limited to, ink nibs, accessories, adaptors, and
                    power supply devices;
                  </li>
                  <li>Purchased from an unauthorized dealer or distributor;</li>
                  <li>
                    Purchased secondhand or from an unauthorized reseller; or
                  </li>
                  <li>Used for commercial purposes (including rentals).</li>
                </ul>
                <p>
                  Each of the above will cause the Product to be an “Ineligible
                  Product”.
                </p>
                <p>
                  Joto will have no warranty obligation with respect to Joto
                  products (even if packaged or sold with a Product) or the
                  following (collectively, “Ineligible Products”):
                </p>
                <ul>
                  <li>
                    Product that has no defects in materials or workmanship;
                  </li>
                  <li>
                    Parts subject to wear and tear due to normal use or natural
                    wear and tear, as well as Product defects caused by wear and
                    tear due to normal use or natural wear and tear;
                  </li>
                  <li>
                    Products with defects that are not reproducible by Joto;
                  </li>
                  <li>
                    Minor reductions in desired quality that do not
                    substantially change the value and usability of the Product;
                  </li>
                  <li>Products marked as “sample” or sold “AS IS”; or</li>
                  <li>
                    Damage cause by any Acts of God, including fire, flood,
                    tornado, earthquake, hurricane, excessive snow, lightning,
                    riot, insurrection, act of war or other disaster.
                  </li>
                </ul>
                <p>
                  Joto will have no warranty obligations with respect to a
                  returned Product if it determines, in its reasonable
                  discretion after analysis of the returned Product, that the
                  Product is an Ineligible Product.
                </p>
                <p>
                  Please note that Products that have been returned to us, and
                  which have visibly been opened or dissembled (other than as a
                  result of direct instruction by Joto customer service
                  personnel), are not covered by this warranty and will not be
                  repaired or returned to the user.
                </p>
                <h3>RETURN SHIPPING COSTS</h3>
                <p>
                  Joto will bear all costs of return shipping to Customer,
                  except with respect to any Ineligible Product, for which
                  Customer will bear all shipping costs. Invalid warranty claims
                  may be repairable, in which case you may be offered an
                  estimate of the cost involved.
                </p>
                <h3>NON-JOTO REFILL PACKS</h3>
                <p>
                  Joto is unable to warrant the quality, safety or output
                  relating to any refill packs that is not supplied or approved
                  by Joto.
                </p>
                <h3>CONTACTING US</h3>
                <p>
                  Email help@joto.rocks to contact the Joto Service Department
                  about warranty issues. Before returning a product to Joto,
                  notify the Joto Service Department. If the service
                  representative is unable to solve your problem, you will be
                  provided with instructions as to how to have the product
                  repaired or replaced. If Joto believes that there may be a
                  valid warranty claim and confirms the original date of
                  purchase for the product, Joto will provide you instructions
                  for returning the product.
                </p>
                <h3>MODIFICATIONS TO YOUR PRODUCTS BY US</h3>
                <p>
                  You understand and acknowledge that in the event that you
                  return your product for repair, it may become necessary for
                  Joto to provide certain services to your Product to ensure it
                  is functioning properly. Such services may include the
                  installation of the latest firmware updates. You acknowledge
                  and agree that some services could change your current
                  settings or cause some changes to functionality.
                </p>
                <h3>DISCLAIMER OF STATUTORY WARRANTIES AND CONDITIONS</h3>
                <p>
                  EXCEPT TO THE EXTENT SET FORTH ABOVE IN THIS LIMITED WARRANTY,
                  THE PRODUCTS ARE PROVIDED ON AN “AS IS” BASIS, AND, TO THE
                  MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, JOTO DISCLAIMS ALL
                  EXPRESS, IMPLIED, AND STATUTORY WARRANTIES AND CONDITIONS WITH
                  RESPECT TO THE PRODUCTS. JOTO LIMITS ALL IMPLIED WARRANTIES
                  WITH RESPECT TO THE PRODUCTS TO THE WARRANTY PERIOD, INCLUDING
                  THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT OF THIRD-PARTY
                  RIGHTS, QUIET ENJOYMENT, AND ACCURACY. JOTO DOES NOT WARRANT
                  THAT OPERATION OF THE PRODUCTS WILL BE ERROR-FREE OR
                  UNINTERRUPTED. HOWEVER, IF SUCH WARRANTIES ARE REQUIRED AS A
                  MATTER OF LAW, THEN THEY ARE LIMITED IN DURATION TO THE
                  WARRANTY PERIOD AS PRESCRIBED BY LOCAL LAW.
                </p>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>
                  YOUR SOLE AND EXCLUSIVE RECOURSE IN THE EVENT OF ANY
                  DISSATISFACTION WITH OR DAMAGE ARISING FROM THE USE OF THE
                  PRODUCT OR REPAIR OF THE PRODUCT AND JOTO’S TOTAL CUMULATIVE
                  LIABILITY ARISING FROM OR RELATED TO THIS LIMITED WARRANTY AND
                  THE PRODUCTS, WHETHER IN CONTRACT OR TORT OR OTHERWISE, SHALL
                  BE LIMITED TO REPAIR OR REPLACEMENT OF THE PRODUCT.
                </p>
                <p>
                  IN NO EVENT WILL JOTO BE LIABLE FOR ANY INJURY, ANY LOSS OF OF
                  PROFIT, OR ANY OTHER LOSS OR DAMAGE SUFFERED BY YOU OR ANY
                  THIRD PARTY, WHETHER SUCH DAMAGES ARE DIRECT, INDIRECT,
                  CONSEQUENTIAL, SPECIAL OR INCIDENTAL AND ARISING UNDER ANY
                  THEORY OF LAW, RELATING TO THIS WARRANTY OR YOUR USE OF, OR
                  THE INABILITY TO USE, THE PRODUCT, EVEN IF JOTO KNEW OR SHOULD
                  HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                  USER SHALL DETERMINE THE SUITABILITY OF THE PRODUCT FOR HIS OR
                  HER INTENDED USE AND THE USER ASSUMES ALL RISKS AND LIABILITY
                  WHATSOEVER IN CONNECTION THEREWITH.
                </p>
                <h3>MODIFICATION OF WARRANTY UNDER APPLICABLE LOCAL LAW</h3>
                <p>
                  SOME STATES AND/OR NON-US JURISDICTIONS DO NOT ALLOW
                  LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS OR
                  EXCLUSIONS/LIMITATIONS ON INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                  SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THIS WARRANTY
                  GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
                  RIGHTS WHICH VARY FROM STATE TO STATE OR FROM JURISDICTION TO
                  JURISDICTION. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF
                  LIABILITY UNDER THIS LIMITED WARRANTY WILL NOT APPLY TO THE
                  EXTENT PROHIBITED BY APPLICABLE LAW.
                </p>
                <p>
                  The warranty offered by Joto is the same whether or not you
                  register your product.
                </p>
                <h3>JOTO REFILLS AND OTHER ACCESSORIES</h3>
                <p>
                  If you are unhappy with your Joto refill pack(s) and/or
                  accessories (not including the stand, which is covered by the
                  Limited Product Warranty above) when you receive it/them or if
                  you simply change your mind please return the items to us
                  within 7 days, with the despatch note, having taken reasonable
                  care of the items and with packaging and all components, and
                  we will refund you the price you paid for that product. You
                  will only have to pay for the return postage of the products
                  to us.
                </p>
                <p>
                  Please note that this does not apply to any refill packs or
                  accessories where the packaging of that refill pack or
                  accessory has been opened by you.
                </p>
                <p>
                  Email help@joto.rocks all claims and product return
                  instructions.
                </p>
                <p></p>
              </div>
            </Col>
          </Row>
        </Container>
      </BodyWrapper>
    </Layout>
  )
}

export default Warranty
